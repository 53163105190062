import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				CourtMasters Tennis Club
			</title>
			<meta name={"description"} content={"Where Passion Meets Game - CourtMasters, Your Tennis Haven"} />
			<meta property={"og:title"} content={"CourtMasters Tennis Club"} />
			<meta property={"og:description"} content={"Where Passion Meets Game - CourtMasters, Your Tennis Haven"} />
			<meta property={"og:image"} content={"https://starledo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://starledo.com/img/Sport-tennis-icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://starledo.com/img/Sport-tennis-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://starledo.com/img/Sport-tennis-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://starledo.com/img/Sport-tennis-icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://starledo.com/img/Sport-tennis-icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://starledo.com/img/Sport-tennis-icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(0deg,rgba(0,0,0,.2) 0%,rgba(0,0,0,.2) 100%),--color-dark url(https://starledo.com/img/5.jpg) 50% 15%/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="50px"
					text-transform="uppercase"
					font="100 132px/1.2 --fontFamily-sans"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					Contact Us
				</Text>
			</Box>
			<Box display="flex" margin="-16px -16px -16px -16px" flex-wrap="wrap" width="100%">
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex" font="--lead">
						<Text margin="0px">
							Contacts:
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							+61 2 9887 0820
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							contact@starledo.com
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							Herring Road &, Waterloo Rd, Macquarie Park NSW 
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});